<template>
  <body>
    <div class="printeighty" style="width: 100%; text-align: left">
      <p>{{ StoreSettingsOpt.Name }}</p>
      <p>{{ StoreSettingsOpt.TaxNumber }}</p>
      <p>{{ StoreSettingsOpt.PhoneNumber }}</p>
      <p>{{ StoreSettingsOpt.Images }}</p>
      <p>{{ StoreSettingsOpt.ShopBossName }}</p>
      <p>{{ MakertOpt.OrderNumber }}</p>
    </div>
    <hr />
    <div style="width: 100%; text-align: left">
      <table style="width: 100%">
        <tr>
          <th></th>
          <th>{{ tools.GetLanguageValue("web.单价") }}</th>
          <th>{{ tools.GetLanguageValue("web.数量") }}</th>
          <th>{{ tools.GetLanguageValue("web.合计") }}</th>
        </tr>
        <tr v-for="(item, index) in MakertOpt.OrderItems" :key="index">
          <td>{{ item.Name }}</td>
          <td>{{ item.Price }}</td>
          <td>{{ item.Count }}</td>
          <td>{{ item.TotalFormate }}</td>
        </tr>
      </table>
      <hr />
      <div class="marketTotalInfo">
        <div class="marketTotalInfo_item">
          <div class="marketTotalInfo_title">
            {{ tools.GetLanguageValue("web.金额") }}
          </div>
          <div class="marketTotalInfo_value">{{ MakertOpt.AmountFormate }}</div>
        </div>
      </div>

      <div class="marketTotalInfo">
        <div class="marketTotalInfo_item">
          <div class="marketTotalInfo_title">
            {{ tools.GetLanguageValue("web.税额") }}
          </div>
          <div class="marketTotalInfo_value">
            {{ MakertOpt.TaxAmountFormate }}
          </div>
        </div>
      </div>

      <div class="marketTotalInfo">
        <div class="marketTotalInfo_item">
          <div class="marketTotalInfo_title">
            {{ tools.GetLanguageValue("web.税率") }}
          </div>
          <div class="marketTotalInfo_value">
            {{ MakertOpt.TaxAmountFormate }}
          </div>
        </div>
      </div>

      <div class="marketTotalInfo">
        <div class="marketTotalInfo_item">
          <div class="marketTotalInfo_title">合计金额</div>
          <div class="marketTotalInfo_value">{{ MakertOpt.TotalFormate }}</div>
        </div>
      </div>
    </div>
  </body>
</template>
  
  <script setup>
import "../PrintCss/print.css";
import "../PrintCss/printeighty.css";
import "../PrintCss/custom-flex.css";

import { ref, onMounted } from "vue";
import axios from "../../../commons/AxiosMethod.js";
import tools from "../../../commons/tools.js";
import JsBarcode from "jsbarcode";
import { useRoute } from "vue-router";
const route = useRoute();
const MakertId = ref("");
const MakertOpt = ref("");
const StoreSettingsOpt = ref("");

// 页面加载
onMounted(() => {
  MakertId.value = route.query.id;
  GetMarketDetail(MakertId.value);
  GetStoreSettings();
  showPrintPage();
});

const showPrintPage = () => {
  console.log("The component is now mounted.");
  setTimeout(() => {
    window.print();
    setTimeout(() => {
      console.log("fffff");
      // debugger;
      closeCurrentPage();
    }, 500);
  }, 1500);
};
//获取销售单详细
const GetStoreSettings = () => {
  const param = {};
  axios.apiMethod("/stores/Setting/GetStoreSettings", "get", {}, (result) => {
    StoreSettingsOpt.value = result.Data;
  });
};

//获取销售单详细
const GetMarketDetail = (orderId) => {
  const param = {};
  axios.apiMethod(
    "/stores/WorkbenchMarket/GetMarketDetail",
    "get",
    {
      orderId: orderId,
    },
    (result) => {
      if (result.Data.IsSuccess) {
        MakertOpt.value = result.Data.Model;
      }
    }
  );
};
</script>
  <style lang="less" scoped>
/* <div class="marketTotalInfo">
        <div class="marketTotalInfo_item">
            <div class="marketTotalInfo_title">金额</div>
            <div class="marketTotalInfo_value">{{ MakertOpt.AmountFormate }}</div>
        </div>
      </div> */

.marketTotalInfo {
  width: 100%;
  .marketTotalInfo_item {
    display: flex;
    .marketTotalInfo_title {
      width: 120px;
    }
    .marketTotalInfo_value {
      width: calc(100% - 120px);
    }
  }
}
</style>
  